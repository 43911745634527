import React from 'react';
import { SALESFORCE_SERVICES } from '../constants';

const SalesforceServicesPopList = () => (
  <ul data-testid="salesforce-services-list">
    {Object.values(SALESFORCE_SERVICES).map(item => (
      <li key={item} className="slds-p-vertical_xx-small">
        {item}
      </li>
    ))}
  </ul>
);

export default SalesforceServicesPopList;
