import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@salesforce/design-system-react';

import avatarImg from './images/avatar.png';

const UserAvatar = ({ avatarUrl = avatarImg, userName = 'User Name' }) => {
  return <Avatar assistiveText={{ icon: 'Avatar image' }} imgSrc={avatarUrl} imgAlt={userName} size="large" />;
};

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  userName: PropTypes.string,
};

export default UserAvatar;
