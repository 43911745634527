const SALESFORCE_SERVICES = {
  Sales_Cloud: 'Sales Cloud',
  Service_Cloud: 'Service Cloud',
  'LiveAgent_Omni-Channel': 'LiveAgent / OmniChannel',
  Lightning_Platform: 'Lightning Platform',
  Einstein_Analytics: 'Einstein Analytics',
  Financial_Services_Cloud: 'Financial Services Cloud',
  Health_Cloud: 'Health Cloud',
  CPQ_and_Billing: 'CPQ and Billing',
};

const DATE_FORMAT = 'MMM DD';

const TIME_FORMAT = 'h:mm a z';

const TIMESTAMP_FORMAT = TIME_FORMAT + ', ' + DATE_FORMAT;

export { SALESFORCE_SERVICES, DATE_FORMAT, TIMESTAMP_FORMAT, TIME_FORMAT };
