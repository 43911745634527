import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const MobileMenuItem = ({ title, linkUrl, onClick, className, children }) => {
  return (
    <li
      className={cx('slds-grid slds-grid_vertical-align-center slds-text-color_default slds-p-left--large', className)}
      onClick={onClick}
    >
      <a href={linkUrl}>{children || title}</a>
    </li>
  );
};

MobileMenuItem.propTypes = {
  title: PropTypes.string,
  linkUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default MobileMenuItem;
