import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import classnames from 'classnames';
import Localized from '../Localized';
import { StatusIcon } from '../../../status2/components/SVGIcon';

import './SubscriptionGrid.scss';

const StatusIconWithMargin = styled(StatusIcon)`
  margin-right: 0.5rem;
`;

export default class SubscriptionGrid extends React.Component {
  static propTypes = {
    instanceSubscription: PropTypes.object,
    severities: PropTypes.array,
    services: PropTypes.array,
    clickAllServices: PropTypes.func,
    clickService: PropTypes.func,
    clickServiceSeverity: PropTypes.func,
    readOnly: PropTypes.bool,
    invalid: PropTypes.bool
  };

  render() {
    const { instanceSubscription, severities, services, readOnly, invalid } = this.props;
    const { clickAllServices, clickService, clickServiceSeverity } = this.props;

    const allChecked = _.every(instanceSubscription.services, (service) => {
      return _.every(service.severities);
    });

    const allIndeterminate = !allChecked && _.some(instanceSubscription.services, (service) => {
      return _.some(service.severities);
    });

    // Set indeterminate status on DOM element, cannot set using React props
    // http://webroo.org/articles/2016-02-10/indeterminate-checkboxes-in-react/
    const allRef = (input) => {
      if (input) input.indeterminate = allIndeterminate;
    };

    // All callbacks are wrapped with (args) => callback && callback(args)
    // to check for their existance, as they are not provided in the read-only version
    return (
      <div className='slds-grid slds-wrap subscription-grid'>
        <div className='slds-grid slds-size--1-of-1 subscription-grid-row'>
          <div className='slds-col slds-size--1-of-4 subscription-grid-header subscription-grid-header__all'>
            <div className='slds-grid slds-nowrap slds-grid--align-spread slds-medium-grid--align-start'>
              <div className='slds-order--1 slds-medium-order--2'>
                <span className='slds-text-title--caps'>
                  ALL SERVICES
                </span>
              </div>
              <div className='slds-form-element slds-order--2 slds-medium-order--1'>
                <span className='slds-checkbox' onClick={() => clickAllServices && clickAllServices()}>
                <input
                  type='checkbox'
                  name='options'
                  checked={allChecked}
                  ref={allRef}
                  disabled={readOnly}
                />
                <label className='slds-checkbox__label' htmlFor='all-services'>
                  <span className='slds-checkbox--faux'></span>
                </label>
                </span>
              </div>
            </div>
          </div>
          { severities.map((severity) => {
            const iconClasses = classnames(
              'subscription-grid-icon',
              'subscription-grid-icon-' + severity.apiKey
            );

            return (
            <div key={severity.apiKey} className='slds-col slds-size--1-of-4 slds-medium-show subscription-grid-cell'>
              <StatusIconWithMargin size='1rem' className={iconClasses} severity={severity.apiKey} />
              <Localized modelName={'statusKey'} modelKey={severity.localizationKey} modelAttribute={'label'} />
            </div>
            );
          })}
        </div>
        { services.map((service) => {
          const serviceSubscription = instanceSubscription.services[service.key];
          const serviceChecked = _.every(serviceSubscription.severities);
          const serviceIndeterminate = _.some(serviceSubscription.severities) && !serviceChecked;

          // Set indeterminate status on DOM element, cannot set using React props
          // http://webroo.org/articles/2016-02-10/indeterminate-checkboxes-in-react/
          const ref = (input) => {
            if (input) input.indeterminate = serviceIndeterminate;
          };

          return (
            <div
              key={service.key}
              className='slds-grid slds-wrap slds-medium-nowrap slds-size--1-of-1 subscription-grid-row'
            >
              <div className='slds-col slds-size--1-of-4 subscription-grid-header'>
                <div className='slds-grid slds-nowrap slds-grid--align-spread slds-medium-grid--align-start'>
                  <div className='slds-order--1 slds-medium-order--2'>
                    <span className='slds-text-title--caps'>
                      <Localized modelName='service' modelKey={service.key} modelAttribute='label' />
                    </span>
                  </div>
                  <div className='slds-form-element slds-order--2 slds-medium-order--1'>
                    <span className='slds-checkbox' onClick={() => clickService && clickService(service.key)}>
                    <input
                      type='checkbox'
                      name={service.key}
                      checked={serviceChecked}
                      ref={ref}
                      disabled={readOnly}
                    />
                    <label className='slds-checkbox__label' htmlFor='all-services'>
                      <span className='slds-checkbox--faux'></span>
                    </label>
                    </span>
                  </div>
                </div>
              </div>
              { severities.map((severity) => {
                const checked = _.get(instanceSubscription,
                                      ['services', service.key, 'severities', severity.apiKey],
                                      false);
                const onClick = () => clickServiceSeverity && clickServiceSeverity(service.key, severity.apiKey);

                const wrapperClasses = classnames(
                  'slds-grid slds-nowrap',
                  'slds-grid--align-spread',
                  'slds-medium-grid--align-space',
                  { 'slds-has-error': invalid }
                );

                const iconClasses = classnames(
                  'subscription-grid-icon',
                  'subscription-grid-icon-' + severity.apiKey
                );
                return (
                  <div
                    key={severity.apiKey}
                    className='slds-col slds-size--1-of-1 slds-medium-size--1-of-4 subscription-grid-cell'
                  >
                    <div className={wrapperClasses}>
                      <span className='slds-medium-hide--inline'>
                        <StatusIconWithMargin size='1rem' className={iconClasses} severity={severity.apiKey} />
                        <Localized
                          modelName={'statusKey'}
                          modelKey={severity.localizationKey}
                          modelAttribute={'label'}
                        />
                      </span>
                      <span className='slds-checkbox slds-m-right--x-small' onClick={onClick}>
                        <input
                          type='checkbox'
                          name={`${service.key}_${severity.apiKey}`}
                          checked={checked}
                          disabled={readOnly}
                        />
                        <label className='slds-checkbox__label' htmlFor={`${service.key}_${severity.apiKey}`}>
                          <span className='slds-checkbox--faux'></span>
                          <span className='slds-form-element__label'>
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}
