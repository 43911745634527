import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@salesforce/design-system-react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import UserAvatar from './UserAvatar';
import Localized from '../V1/shared/components/Localized';

import ExpandableMenu from './ExpandableMenu';
import MobileMenuItem from './MobileMenuItem';
import { selectAllProducts } from '../modules/products/selectors';

import './MobileMenu.scss';

const MobileMenu = ({ showMenu, onClose, onLogout, settingsMenuOptions, helpMenuOptions }) => {
  const [activeMenu, setActiveMenu] = useState('');
  const history = useHistory();

  const products = useSelector(selectAllProducts).map(({ key }) => ({
    title: key,
    modelName: 'product',
    url: `/products/${key}`,
  }));

  const currentUser = useSelector(state => get(state, 'requests.subscriber.value'));

  const handleLogin = () => {
    history.push('/login');
    onClose();
  };

  if (!showMenu) return null;
  return (
    <div className="mobile-menu" data-testid="mobile-menu">
      <section className="slds-grid slds-grid_vertical-align-center slds-m-horizontal--medium close-section">
        <Button
          assistiveText={{ icon: 'Close' }}
          iconCategory="utility"
          iconName="close"
          iconSize="large"
          variant="icon"
          onClick={onClose}
        />
      </section>
      <section className="slds-grid slds-grid_vertical-align-center slds-p-horizontal--large slds-p-vertical--x-large slds-m-bottom--xx-small user-section">
        <UserAvatar />
        <div className="slds-grid slds-grid_vertical slds-grid_vertical-align-start slds-m-left--small ">
          {currentUser ? (
            <p>{currentUser.emailAddress}</p>
          ) : (
            <Button label="Login" onClick={handleLogin} variant="base" title="Login" />
          )}
          <label className="slds-text-color_weak">Manage Trust Subscriptions</label>
        </div>
      </section>
      <section>
        <nav className="slds-nav-vertical" aria-label="mobile menu navigation" data-testid="mobile-menu-nav">
          <ul className="slds-nav-vertical__section">
            <MobileMenuItem linkUrl="/current" className="mobile-menu-item">
              <Localized className="slds-col" modelName="product" modelKey="Home" modelAttribute="label" />
            </MobileMenuItem>
            <div className="slds-border--top slds-m-horizontal--large slds-m-bottom--xx-small" />
            <ExpandableMenu
              title="Status & Maintenance"
              headClassName="slds-m-horizontal--large mobile-menu-item"
              options={products}
              onTitleClick={menuTitle => setActiveMenu(menuTitle)}
              isActive={activeMenu === 'Status & Maintenance'}
            />
            {currentUser && (
              <>
                <MobileMenuItem linkUrl="/subscriptions" className="mobile-menu-item">
                  <Localized modelKey="mySubscription" modelAttribute="userOptions" modelName="iconDropdownMenu" />
                </MobileMenuItem>

                <div className="slds-border--top slds-m-horizontal--large slds-m-bottom--xx-small" />
              </>
            )}
            <MobileMenuItem linkUrl="/generalmessages" className="mobile-menu-item">
              <Localized
                className="slds-col"
                modelName="generalMessage"
                modelKey="generalMessage"
                modelAttribute="label"
              />
            </MobileMenuItem>
            <div className="slds-border--top slds-m-horizontal--large slds-m-bottom--xx-small" />
            <ExpandableMenu
              title="Settings"
              headClassName="slds-m-horizontal--large mobile-menu-item"
              options={settingsMenuOptions}
              onTitleClick={menuTitle => setActiveMenu(menuTitle)}
              isActive={activeMenu === 'Settings'}
            />
            <ExpandableMenu
              title="Help Links"
              headClassName="slds-m-horizontal--large mobile-menu-item"
              options={helpMenuOptions}
              onTitleClick={menuTitle => setActiveMenu(menuTitle)}
              isActive={activeMenu === 'Help Links'}
            />
            {currentUser && <MobileMenuItem title="Logout" onClick={onLogout} className="mobile-menu-item" />}
          </ul>
        </nav>
      </section>
    </div>
  );
};

MobileMenu.propTypes = {
  showMenu: PropTypes.bool,
  settingsMenuOptions: PropTypes.array,
  helpMenuOptions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default MobileMenu;
