import React from 'react';
//import Localized from '../V1/shared/components/Localized';

const textMaintenance = `Whenever possible, and only as maintenance is necessary,
  Salesforce will schedule system maintenance the first and third weekends of the
  calendar month during the window listed. When a calendar month begins on a Sunday,
  we would hold system maintenance the first and third full weekends of the month.`;

const MaintenanceTooltip = () => (
  <div data-testid={'maintenance-tooltip'}>
    {textMaintenance}
    {/*<Localized modelKey={textMaintenance} modelName={'instanceInfo'} modelAttribute={'tooltip'}/> */}
  </div>
);

export default MaintenanceTooltip;
