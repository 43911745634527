import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Combobox,
  GlobalHeaderSearch,
  Icon,
  comboboxAddSubheadings,
  comboboxFilterAndLimit,
} from '@salesforce/design-system-react';

import './GlobalSearch.scss';

const GlobalSearch = () => {
  const [state, setState] = useState({ textValue: '', openedListbox: false });
  const history = useHistory();
  const placeholderText = 'Search Instance, Domain, Pod, or MID';

  const getInstancesFromLocalStorage = () => {
    if (window.localStorage.getItem('instanceList')) {
      const arrayInstances = JSON.parse(window.localStorage.getItem('instanceList'));
      const recentInstances = arrayInstances.map(inst => {
        return {
          id: inst.key,
          label: inst.key,
          subTitle: 'Instance',
          type: 'recent',
          icon: <Icon assistiveText={{ label: 'Instance' }} category="standard" name="orders" />,
        };
      });
      return recentInstances;
    }
    return [];
  };

  const subheadings = [
    {
      id: 'recent',
      label: 'Recent Search',
      type: 'separator',
    },
  ];

  const onKeyChange = async e => {
    setState({ ...state, textValue: e.target.value, openedListbox: false });
  };

  return (
    <div className="global-search-wrapper" data-testid="global-header-search">
      <GlobalHeaderSearch
        combobox={
          <Combobox
            assistiveText={{ label: 'Search' }}
            className="slds-m-top_x-small slds-large-size_7-of-8"
            events={{
              onBlur: () => {
                setState({ ...state, openedListbox: false });
              },
              onChange: e => {
                onKeyChange(e);
              },
              onFocus: () => {
                setState(state => {
                  if (!state.textValue || state.textValue.length === 0) {
                    const focusOpen = { ...state, openedListbox: true };
                    return focusOpen;
                  }
                  const focusClosed = { ...state, openedListbox: false };
                  return focusClosed;
                });
              },
              onSelect: (e, data) => {
                history.push('/instances/' + data.selection[0].label);
                setState({ textValue: '', openedListbox: false });
              },
              onSubmit: () => {
                history.push('/search/' + state.textValue);
                setState({ textValue: '', openedListbox: false });
              },
            }}
            id="global-header-search"
            isOpen={state.openedListbox}
            labels={{ placeholder: placeholderText }}
            options={comboboxAddSubheadings({
              subheadings,
              filteredOptions: comboboxFilterAndLimit({
                inputValue: '',
                limit: 5,
                options: getInstancesFromLocalStorage(),
                selection: [],
              }),
            })}
            value={state.textValue}
          />
        }
      />
    </div>
  );
};

export default GlobalSearch;
