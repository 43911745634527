import React from 'react';

const TableauPopList = () => (
  <div>
    <b>What pod am I on?</b>
    <p>Your pod is shown in the first portion of the site URL after signing in. </p>
    <br />
    <p>
      <u>Example:</u>
    </p>
    <p>https://10az.online.tableau.com is the United States - West (10AZ) pod.</p>
  </div>
);

export default TableauPopList;
