import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Localized from '../V1/shared/components/Localized';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SALESFORCE_SERVICES } from '../constants';
import { selectAllActiveInstances } from '../modules/instances/selectors';
import { Popover, Button } from '@salesforce/design-system-react';
import SalesforceServicesPopList from './SalesforceServicesPopList';
import SubscribeButton from '../V1/status2/components/Button/Button.js';
import './ProductHeader.scss';

const SubscribeButtonLarge = styled(SubscribeButton)`
  margin-top: 0.25rem;
  @media (min-width: 320px) and (max-width: 1022px) {
    display: none;
  }
`;

const SubscribeButtonSmall = styled(SubscribeButton)`
  @media (min-width: 1023px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  ${SubscribeButton} + ${SubscribeButton} {
    margin-left: 0.25rem;
  }
`;

const ProductHeader = ({ product }) => {
  const instances = useSelector(selectAllActiveInstances);

  return (
    <div className="slds-page-header product-header" data-testid="product-header">
      <div className="slds-page-header__row">
        <div className="slds-page-header__col-title">
          <div className="slds-media">
            <div className="slds-media__figure">
              <span className="slds-icon_container product-header-icon" title={product.key}>
                <img
                  className="slds-icon slds-page-header__icon"
                  src={`/images/${product.key.toLowerCase()}.svg`}
                  alt={product.key}
                />
              </span>
            </div>
            <div className="slds-media__body">
              <div className="slds-page-header__name">
                <div className="slds-page-header__name-title">
                  <h1>
                    <Localized
                      className="slds-page-header__title slds-truncate"
                      modelName="product"
                      modelKey={product.key}
                      modelAttribute="label"
                    />
                  </h1>
                </div>
              </div>
              <div className="slds-page-header__name-meta">
                <ul className="slds-list_horizontal slds-has-dividers_left">
                  {product.key.toLowerCase() === 'salesforce_services' && (
                    <Popover
                      body={<SalesforceServicesPopList />}
                      heading="Salesforce Services"
                      align="bottom left"
                      contentsClassName="slds-popover--small"
                    >
                      <li className="slds-item" data-testid="salesforce-link">
                        <Button variant="link">{`${
                          Object.keys(SALESFORCE_SERVICES).length
                        } Products & Services`}</Button>
                      </li>
                    </Popover>
                  )}
                  <li className="slds-item" data-testid="instances">
                    {instances.length} Instances
                  </li>
                </ul>
              </div>
            </div>
            <div class="slds-subscribe-button">
              <span class="slds-icon_container product-header-icon">
                <ButtonWrapper>
                  <Link to={'/subscriptions'}>
                    <SubscribeButtonLarge theme="brand">
                      <Localized modelKey="headerModal" modelName="signupModal" modelAttribute="value" />
                    </SubscribeButtonLarge>
                  </Link>
                  <Link to={'/subscriptions'}>
                    <SubscribeButtonSmall theme="brand">
                      <Localized modelKey="subscribe" modelName="button" modelAttribute="label" />
                    </SubscribeButtonSmall>
                  </Link>
                </ButtonWrapper>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductHeader.propTypes = {
  product: PropTypes.object,
};

export default ProductHeader;
