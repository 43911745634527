import { connect } from 'react-redux';
export default connect(
  undefined,
  (dispatch) => {
    return {
      setSearchBoxValue: (value) => {
        dispatch({
          type: '@@globalHeader/SET_SEARCH_BOX_VALUE',
          value,
        });
      }
    };
  }
);
