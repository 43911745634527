import React from 'react';
import styled from 'styled-components';
import Localized from '../../../shared/components/Localized';
import Checkbox from '../Form/Checkbox';

const CheckBoxDiv = styled.div`
  flex: 1;
  margin-bottom: 0.7499rem;
  display: flex;
  align-items: center;
  > :nth-child(2) {
    font-family: Salesforce Sans;
    font-size: 0.8125rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #706e6b;
    width: 2.526rem;
  }
  > :nth-child(3) {
    font-family: Salesforce Sans;
    font-size: 0.8125rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #2b2826;
    line-height: 1.125rem;
  }
  > :nth-child(4) {
    margin-left: 0.5rem;
    color: ${props => (props.verified ? '#2ead5d' : '#b0adab')};
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const SubscriptionChannel = ({ subType, subText, checked, verified, onChange }) => {
  const id = `SubscriptionChannel_${subType}`;

  const renderText = () => {
    if (subType === 'SMS') {
      return subText || 'No mobile number added';
    }
    return subText;
  };
  return (
    <CheckBoxDiv verified={verified}>
      <Checkbox id={id} type="checkbox" checked={checked} disabled={!verified} onChange={onChange} />
      <span>{subType}:</span>
      <span>{renderText()}</span>
      {subText && (
        <span>
          <Localized
            modelKey={verified ? 'Verified' : 'Not Verified'}
            modelAttribute="label"
            modelName="subscriptions"
          />
        </span>
      )}
    </CheckBoxDiv>
  );
};

export default SubscriptionChannel;
