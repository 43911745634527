import React from 'react';
import { Link } from 'react-router-dom';
import Localized from '../V1/shared/components/Localized';
import { Tooltip } from '@salesforce/design-system-react';
import _ from 'lodash';
import './InstanceInfo.scss';
import MaintenanceTooltip from './MaintenanceTooltip';

const InstanceInfo = ({ instance, onClick }) => {
  // map tagType -> tagNames
  const tagMap = instance.Tags.reduce((tagMap, tag) => {
    // To exclude superpod and FI info on the UI
    if (tag.type.toLowerCase() === 'superpod' || tag.type.toLowerCase() === 'fi') {
      return tagMap;
    }
    if (tagMap[tag.type]) {
      tagMap[tag.type].push(tag.value);
    } else {
      tagMap = { ...tagMap, [tag.type]: [tag.value] };
    }
    return tagMap;
  }, {});
  const maintenance = instance.maintenanceWindow;

  const renderLabel = key => (
    <h6 className="slds-text-title slds-text-color_weak label">
      <Localized modelName={'instanceInfo'} modelAttribute={'label'} modelKey={key} />
    </h6>
  );

  const renderReleaseVersion = (value, key) => {
    if (_.isEmpty(value)) return null;
    return (
      <div className="slds-m-bottom--medium slds-text-body_regular slds-p-top_medium">
        {renderLabel(key)}
        <p className="value">{value}</p>
      </div>
    );
  };

  const renderLocation = valueKey => {
    if (_.isEmpty(valueKey)) return null;
    return (
      <div className="slds-m-bottom--medium slds-p-top_xx-small">
        {renderLabel('region')}
        <p className="value">
          <Localized modelName={'InstanceLocation'} modelAttribute={'tabTitle'} modelKey={valueKey} />
        </p>
      </div>
    );
  };

  const renderDataCenter = (city, country) => {
    if (_.isEmpty(city)) return null;
    return (
      <div className="slds-m-bottom--medium slds-p-top_xx-small">
        {renderLabel('Data Center')}
        <p className="value">{city + ', ' + country}</p>
      </div>
    );
  };

  const renderMaintenanceWindow = () => {
    if (_.isEmpty(maintenance)) return null;
    return (
      <div className="slds-m-bottom--medium slds-p-top_xx-small">
        <h6 className="slds-text-title label">
          <Localized
            modelName={'instanceInfo'}
            modelAttribute={'label'}
            modelKey={'Maintenance Window'}
            className={'slds-m-right--xx-small'}
          />
          <Tooltip
            id="base"
            align="top right"
            content={<MaintenanceTooltip />}
            variant="learnMore"
            position="overflowBoundaryElement"
          />
        </h6>
        <p className="value">{maintenance.substring(0, maintenance.indexOf(' '))}</p>
        <p className="value">{maintenance.substring(maintenance.indexOf(' ') + 1)}</p>
      </div>
    );
  };

  const renderProducts = () => (
    <div className="slds-m-bottom--medium slds-p-top_xx-small">
      {renderLabel('products')}
      <p>
        {instance.Products.map((product, i) => {
          return (
            <span key={i} onClick={onClick} className="value">
              <Link to={'/products/' + product.key}>
                <Localized modelName="product" modelKey={product.key} modelAttribute="label" />
              </Link>
              {instance.Products.length - 1 === i ? '' : ','}
            </span>
          );
        })}
      </p>
    </div>
  );

  const renderTags = () => {
    if (_.isEmpty(tagMap)) return null;
    return Object.entries(tagMap).map(([tagType, tagNames]) => {
      return (
        <div className="slds-m-bottom--medium slds-p-top_xx-small" key={`tag_${tagType}`}>
          <h6 className="slds-text-title">
            <Localized modelName="tagType" modelAttribute="label" modelKey={tagType} />:
          </h6>
          <p id={`value_tag_${tagType}`} className="value">
            {tagNames.map(tagName => (
              <span className="item-tag" key={`tagName_${tagName}`}>
                <Localized modelName="tag" modelAttribute="label" modelKey={tagName} hasLinks={true} />
              </span>
            ))}
          </p>
        </div>
      );
    });
  };

  return (
    <div className="wrapper" data-testid="instance-info">
      <div className="header">
        <Localized modelName="instanceInfo" modelAttribute="label" modelKey="instanceDetails" />
      </div>
      {renderReleaseVersion(instance.releaseVersion, 'version')}
      {renderLocation(instance.location)}
      {renderDataCenter(instance.city, instance.countryCode)}
      {renderMaintenanceWindow(instance.maintenanceWindow)}
      {renderProducts()}
      {renderTags()}
    </div>
  );
};
export default InstanceInfo;
