import React from 'react';
import Localized from '../../../shared/components/Localized';

import './style.css';

const GlobalFooter = () => {
  return (
    <footer className="slds-grid slds-grid_vertical slds-grid_vertical-align-center slds-p-vertical_medium global-footer">
      <nav>
        <ul className="slds-list_horizontal slds-has-dividers_right slds-align_absolute-center">
          <li className="slds-item">
          <a href="https://www.salesforce.com">Salesforce.com</a>
          </li>
          <li className="slds-item">
            <a href="https://trust.salesforce.com/"><Localized modelKey="trustHyperLink" modelAttribute="label" modelName="footer" /></a>
          </li>
          <li className="slds-item">
            <a href="https://www.salesforce.com/company/privacy/">
            <Localized modelKey="privacyPolicy" modelAttribute="label" modelName="footer" />
            </a>
          </li>
          <li className="slds-item">
            <a className="page-footer_link optanon-toggle-display" href="#" data-ignore-geolocation="true">
            <Localized modelKey="cookies" modelAttribute="label" modelName="footer" />
            </a>
          </li>
          <li className="slds-item">
            <a href="https://www.salesforce.com/form/other/privacy-request/">
              <img className="privacy-icon" src="/images/privacychoices.svg" width="32" height="32"/>
              <Localized modelKey="privacyChoices" modelAttribute="label" modelName="footer" />
            </a>
          </li>
        </ul>
      </nav>
      <p>
        &copy; Copyright {new Date().getFullYear()} Salesforce, Inc.&nbsp;
         <Localized modelKey = "copyright" modelAttribute = "label" modelName = "footer" />
      </p>
    </footer>
  );
};

export default GlobalFooter;
