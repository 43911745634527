import forEach from 'lodash/forEach';
import moment from 'moment';
import axios from 'axios';

//Whitelist of fields to eagerly convert into moment objects
const momentWhitelist = [
  'startTime',
  'endTime',
  'startDate',
  'endDate',
  'createdAt',
  'updatedAt',
  'plannedEndTime',
  'plannedStartTime',
  'timestamp',
];

/**
 * Converts datetime like values in response to moment objects.
 *
 * Mutates the response
 *
 * @param  {Object} object Response object
 */
const momentize = object => {
  return forEach(object, (value, key, collection) => {
    if (typeof value === 'object') return momentize(value);

    if (momentWhitelist.indexOf(key) > -1) {
      // test string to see if valid date, if so, then replace
      // adding true makes moment use strict comparison
      const momentValue = moment(value, moment.ISO_8601, true);

      if (momentValue.isValid()) collection[key] = momentValue;
    }
  });
};

const httpGet = async pathUrl => {
  const url = `/api/${pathUrl}`;
  const { data } = await axios.get(url);
  return momentize(data);
};

export { httpGet };
