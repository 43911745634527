import { connect } from 'react-redux';


export default connect(
  (state) => {
    return {
      recentInstance: JSON.parse(state.setInstance),
    };
  },
)

