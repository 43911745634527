import { connectRequests } from '../../../../../higherOrderComponents/requests';

export default connectRequests((state) => {
  const { token } = state;
  return {
    addNumber: {
      method: "POST",
      url: `/addNumber?token=${token}`
    },
    verifyUser: {
      method: "POST",
      url: `/verifyUser?token=${token}`
    },
    countryCodes: {
      url: `/countryCodes`,
      onLoad: true
    }
  };
});
