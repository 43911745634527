import React, { useEffect, useState } from 'react';
import { Modal } from '@salesforce/design-system-react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import IncidentFeed from '../V1/status2/components/Incident/IncidentFeed';
import Localized from '../V1/shared/components/Localized';

// V2 components
import { httpGet } from '../lib/httpClient';
import IncidentInfo from './IncidentInfo';
import './IncidentModal.scss';

const IncidentModal = () => {
  const [incident, setIncident] = useState();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const fetchIncident = async () => {
      const incident = await httpGet(`incidents/${id}`);
      setIncident(incident);
    };
    fetchIncident();
  }, [id]);

  const handleModalClose = () => {
    if (location.state && location.state.modal) {
      history.goBack();
    } else {
      history.push('/current');
    }
  };

  const renderIncidentHistory = () => {
    if (!incident) return null;

    return (
      <section data-testid="incident-history">
        <div className="section-title">
          <p className="section-title_left">
            <Localized modelName={'incidentModal'} modelKey={'incidentHistory'} modelAttribute={'header'} />
          </p>
        </div>
        <IncidentFeed incident={incident} />
      </section>
    );
  };

  return (
    <Modal
      assistiveText={{ dialogLabel: 'Incident Modal' }}
      ariaHideApp={false}
      isOpen
      onRequestClose={handleModalClose}
      heading="Incident Details"
    >
      <section>
        <div className="section-title">
          <p className="section-title_left">
            <Localized modelName={'incidentModal'} modelKey={'incidentInformation'} modelAttribute={'header'} />
          </p>
          <p className="section-title_right">{`ID ${id}`}</p>
        </div>
        <div className="section-content">{incident && <IncidentInfo incident={incident} />}</div>
      </section>
      {renderIncidentHistory()}
    </Modal>
  );
};

export default IncidentModal;
