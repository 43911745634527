import React from 'react';
import { Link } from 'react-router-dom';

class SubscriptionButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.isSubscribed) {
            return (
                <Link
                    to={{
                        pathname: `/subscriptions`
                    }}
                    style={{ textDecoration: 'none'}}
                >
                    <button className="slds-button slds-button_neutral slds-button_stateful slds-is-selected" aria-live="assertive" style={{ backgroundColor: 'white', border: '1px solid #dddbda' }}>
                        <span className="slds-text-not-selected">
                            <svg className="slds-button__icon slds-button__icon_small slds-button__icon_left" aria-hidden="true">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add"></use>
                            </svg>
                            Subscribing
                        </span>
                        <span className="slds-text-selected">
                            <svg className="slds-button__icon slds-button__icon_small slds-button__icon_left" aria-hidden="true">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                            </svg>
                            Subscribing
                        </span>
                        <span className="slds-text-selected-focus">
                            Manage My Subscription
                        </span>
                    </button>
                </Link>
            );
        } else {
            return (
                <Link
                    to={{
                    pathname: `/subscribe/${this.props.instanceKey}`,
                    state: { modal: true }
                    }}
                    style={{ textDecoration: 'none'}}
                >
                    <button className="slds-button slds-button_brand">Subscribe</button>
                </Link>
            );
        }
    }
}

export default SubscriptionButton;