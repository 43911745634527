import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip } from '@salesforce/design-system-react';

import './ProductSideNav.scss';
import Localized from '../V1/shared/components/Localized';
import { selectAllProducts } from '../modules/products/selectors';
import SalesforceServicesPopList from './SalesforceServicesPopList';
import TableauPopList from './TableauPopList';

const ProductSideNav = () => {
  const products = useSelector(selectAllProducts).map(({ key }) => ({
    key,
    url: `/products/${key}`,
  }));

  products.unshift({
    key: 'Status',
    url: '/current',
  });

  return (
    <nav className="slds-nav-vertical product-side-nav" aria-label="products navigation" data-testid="product-side-nav">
      <ul className="slds-nav-vertical__section">
        {products.map(product => (
          <li key={product.key}>
            <NavLink
              className="product-side-nav-item"
              exact
              to={product.url}
              activeClassName="product-side-nav-item_active"
              data-testid="product-side-nav-item"
            >
              <img
                className="product-icon slds-m-right_small"
                src={`/images/${product.key.toLowerCase()}.svg`}
                alt={product.key}
              />
              <Localized className="slds-col" modelName="product" modelKey={product.key} modelAttribute="label" />
              {product.key.toLowerCase() === 'salesforce_services' && (
                <Tooltip
                  align="right top"
                  content={<SalesforceServicesPopList />}
                  variant="list-item"
                  dialogClassName="salesforce-services-tooltip"
                />
              )}
              {product.key.toLowerCase() === 'tableau' && (
                <Tooltip
                  align="right top"
                  content={<TableauPopList />}
                  variant="list-item"
                  dialogClassName="salesforce-services-tooltip"
                />
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ProductSideNav;
