import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@salesforce/design-system-react';
import cx from 'classnames';
import MobileMenuItem from './MobileMenuItem';
import Localized from '../V1/shared/components/Localized';

const ExpandableMenu = ({ title, options, headClassName, isActive, onTitleClick }) => {
  const [isExpanded, setIsExpanded] = useState(isActive);

  const handleTitleClick = title => {
    onTitleClick(title);
    setIsExpanded(!isExpanded);
  };
  return (
    <li
      className={cx('slds-grid slds-grid_vertical slds-m-bottom--xx-small slds-text-color_default', {
        'expand-menu': isExpanded && isActive,
      })}
    >
      <div
        className={cx('slds-grid slds-grid--vertical-align-center', headClassName, 'expand-menu-header', {
          'slds-border--bottom': !isExpanded,
        })}
        onClick={() => handleTitleClick(title)}
        data-testid={`expand-menu-${title}`}
      >
        <label className="slds-col">{title}</label>
        <Button
          assistiveText={{ icon: 'Expand' }}
          iconCategory="utility"
          iconName={isExpanded ? 'chevronup' : 'chevrondown'}
          iconSize="medium"
          variant="icon"
          data-testid="expand-button"
        />
      </div>
      {isExpanded && (
        <nav className="slds-nav-vertical slds-p-left--large" aria-label="mobile menu navigation">
          <ul className="slds-nav-vertical__section">
            {options.map(({ title, modelName, url, onClick }) => (
              <MobileMenuItem
                key={title}
                linkUrl={url}
                onClick={onClick}
                className="slds-p-left--large mobile-menu-item"
              >
                <Localized className="slds-col" modelName={modelName} modelKey={title} modelAttribute="label" />
              </MobileMenuItem>
            ))}
          </ul>
        </nav>
      )}
    </li>
  );
};

ExpandableMenu.propTypes = {
  onTitleClick: PropTypes.func,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  headClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, modelName: PropTypes.string, url: PropTypes.string })
  ),
};

export default ExpandableMenu;
