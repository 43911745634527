import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import humanizeDuration from 'humanize-duration';

import Localized from '../V1/shared/components/Localized';

import { TIMESTAMP_FORMAT } from '../constants';
import { Button } from '@salesforce/design-system-react';

const IncidentInfo = ({ incident }) => {
  const [instanceKeys, setInstanceKeys] = useState([]);

  const visibleInstances = useMemo(() => {
    const MAX_CHAR_LENGTH = 96;
    let charLength = 0;
    const keys = [];
    for (const key of incident.instanceKeys) {
      charLength += key.length + 1;
      if (charLength <= MAX_CHAR_LENGTH) {
        keys.push(key);
      } else {
        break;
      }
    }
    return keys;
  }, [incident.instanceKeys]);

  useEffect(() => {
    setInstanceKeys(visibleInstances);
  }, [visibleInstances]);

  const now = moment().utc();
  const oldestImpact = _.last(incident.IncidentImpacts);
  const latestImpact = _.first(incident.IncidentImpacts);
  const startTime = _.get(oldestImpact, 'startTime', null);
  const endTime = _.get(latestImpact, 'endTime', null);
  const resolved = endTime !== null;

  // calculate before creating synthetic Resolve impact period
  const totalMilliseconds = incident.IncidentImpacts.reduce((duration, impact) => {
    const time = impact.endTime ? impact.endTime : now.clone();
    return duration + time.diff(impact.startTime);
  }, 0);

  const handleToggleShowInstanceKeys = () => {
    if (instanceKeys.length < incident.instanceKeys.length) {
      setInstanceKeys(incident.instanceKeys);
    } else {
      setInstanceKeys(visibleInstances);
    }
  };

  const renderInfoTitle = titleKey => (
    <h6 className="slds-text-title">
      <Localized modelName={'modal'} modelKey={titleKey} modelAttribute={'label'} />
    </h6>
  );

  const renderStatus = () => (
    <div className="slds-m-bottom--medium">
      {renderInfoTitle('status')}
      <p>
        {resolved ? (
          <Localized
            className="slds-text-color_success"
            modelName={'modal'}
            modelKey={'status'}
            modelAttribute={'valueResolved'}
          />
        ) : (
          <Localized modelName={'modal'} modelKey={'status'} modelAttribute={'valueOngoing'} />
        )}
      </p>
    </div>
  );

  const renderToggleButton = () => {
    if (visibleInstances.length === incident.instanceKeys.length) return null;
    const viewAll = instanceKeys.length < incident.instanceKeys.length;

    return (
      <Button
        label={viewAll ? 'View All' : 'Hide'}
        onClick={handleToggleShowInstanceKeys}
        variant="base"
        iconCategory="utility"
        iconName={viewAll ? 'chevrondown' : 'chevronup'}
        iconPosition="left"
      />
    );
  };

  const renderInstances = () => {
    return (
      <div className="slds-m-bottom--medium">
        {renderInfoTitle('relatedInstances')}
        <p>
          {instanceKeys.map((instanceKey, i) => {
            return (
              <span key={i} data-testid="incident-instance">
                <Link to={'/instances/' + instanceKey}>
                  <Localized modelName={'instance'} modelKey={instanceKey} modelAttribute={'label'} />
                </Link>
                {instanceKeys.length - 1 === i ? '' : ', '}
              </span>
            );
          })}
        </p>
        {renderToggleButton()}
      </div>
    );
  };

  const renderServices = () => {
    const { serviceKeys } = incident;
    if (_.isEmpty(serviceKeys)) return null;
    return (
      <div className="slds-m-bottom--medium">
        {renderInfoTitle('relatedServices')}
        <p>
          {serviceKeys.map((serviceKey, i) => {
            return (
              <span key={i}>
                <Localized modelName={'service'} modelKey={serviceKey} modelAttribute={'label'} />
                {serviceKeys.length - 1 === i ? '' : ', '}
              </span>
            );
          })}
        </p>
      </div>
    );
  };

  const renderAdditionalInfo = () => {
    const { additionalInformation } = incident;
    if (_.isEmpty(additionalInformation)) return null;
    return (
      <div className="slds-m-bottom--medium">
        {renderInfoTitle('additionalInformation')}
        <p>
          <Linkify>
            {additionalInformation.split('\\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </Linkify>
        </p>
      </div>
    );
  };

  const renderRightSection = () => {
    const { timezone } = incident;
    const infos = [
      { title: 'startTime', value: moment.tz(startTime, timezone).format(TIMESTAMP_FORMAT) },
      { title: 'endTime', value: endTime ? moment.tz(endTime, timezone).format(TIMESTAMP_FORMAT) : '-' },
      {
        title: 'duration',
        value: humanizeDuration(totalMilliseconds, { largest: 2, round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm'] }),
      },
    ];

    return infos.map(({ title, value }) => (
      <div key={title} className="slds-m-bottom--medium">
        {renderInfoTitle(title)}
        <p>{value}</p>
      </div>
    ));
  };

  return (
    <div className="slds-grid" data-testid="incident-info">
      <div className="slds-col slds-size_2-of-3 slds-p-right--medium">
        {renderStatus()}
        {renderInstances()}
        {renderServices()}
        {renderAdditionalInfo()}
      </div>
      <div className="slds-col slds-size_1-of-3 slds-p-left--medium slds-border_left">{renderRightSection()}</div>
    </div>
  );
};

IncidentInfo.propTypes = {
  incident: PropTypes.object.isRequired,
};

export default IncidentInfo;
